@import url("https://fonts.googleapis.com/css2?family=Open+Sans&family=Raleway:wght@700&display=swap");

html,
body {
   width: 100%;
   margin: 0px;
   padding: 0px;
   background-color: white;
   overflow-x: hidden;
}
html {
   scroll-behavior: smooth;
}

a {
   background-color: transparent;
   color: inherit;
   text-decoration: none;
}
a {
   background-color: transparent;
   color: inherit;
   text-decoration: underline;
}
a:hover,
a:active {
   outline: 0;
   text-decoration: none;
}

.heading {
   color: #2d2a31;
   font-size: 30px;
   font-family: "Raleway", sans-serif;
}

.content {
   color: #39343d;
   font-family: "Open Sans", sans-serif;
   font-size: 18px;
   line-height: 1.6;
}

.blob-btn {
   text-decoration: none !important;
   z-index: 1;
   position: relative;
   color: #58144f;
   outline: none;
   border: none;
   transition: color 0.5s;
   cursor: pointer;
   border-radius: 50px;
   border: 2px solid #58144f;
   text-align: center;
   overflow: hidden;
   width: 7rem;
   height: 0.8rem;
   font-size: 18px;
   min-height: 45px;
   flex-direction: row;
   justify-content: center;
}

.blob-btn-text {
   outline: 0;
   text-decoration: none;
}
.blob-btn-text:hover {
   color: white !important;
}

.blob-btn:hover {
   color: white !important;
   border-radius: 50px;
   border: 2px solid transparent;
}
.blob-btn__inner {
   z-index: -1;
   overflow: hidden;
   position: absolute;
   left: 0;

   top: 0;
   width: 100%;
   height: 100%;
   border-radius: 30px;
   background: #ffffff;
}
.blob-btn__blobs {
   position: relative;
   display: block;
   height: 100%;
   filter: url("#goo");
}
.blob-btn__blob {
   position: absolute;
   top: 3px;
   width: 25%;
   height: 100%;
   background: linear-gradient(#8e27d7, #2e1472);
   border-radius: 100%;
   transform: translate3d(0, 150%, 0) scale(1.7);
   transition: transform 0.45s;
}
@supports (filter: url("#goo")) {
   .blob-btn__blob {
      transform: translate3d(0, 150%, 0) scale(1.4);
   }
}
.blob-btn__blob:nth-child(1) {
   left: 0%;
   transition-delay: 0s;
}
.blob-btn__blob:nth-child(2) {
   left: 30%;
   transition-delay: 0.08s;
}
.blob-btn__blob:nth-child(3) {
   left: 60%;
   transition-delay: 0.16s;
}
.blob-btn__blob:nth-child(4) {
   left: 90%;
   transition-delay: 0.24s;
}
.blob-btn:hover .blob-btn__blob {
   transform: translateZ(0) scale(1.7);
}
@supports (filter: url("#goo")) {
   .blob-btn:hover .blob-btn__blob {
      transform: translateZ(0) scale(1.4);
   }
}
@media (min-device-width: 20px) and (max-device-width: 500px) {
   html,
   body {
      overflow-x: hidden;
   }
   .heading {
      font-size: 32px;
   }

   .content {
      font-size: 15px;
   }
}
