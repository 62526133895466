@import url(https://fonts.googleapis.com/css2?family=Open+Sans&family=Raleway:wght@700&display=swap);
html,
body {
   width: 100%;
   margin: 0px;
   padding: 0px;
   background-color: white;
   overflow-x: hidden;
}
html {
   scroll-behavior: smooth;
}

a {
   background-color: transparent;
   color: inherit;
   text-decoration: none;
}
a {
   background-color: transparent;
   color: inherit;
   text-decoration: underline;
}
a:hover,
a:active {
   outline: 0;
   text-decoration: none;
}

.heading {
   color: #2d2a31;
   font-size: 30px;
   font-family: "Raleway", sans-serif;
}

.content {
   color: #39343d;
   font-family: "Open Sans", sans-serif;
   font-size: 18px;
   line-height: 1.6;
}

.blob-btn {
   text-decoration: none !important;
   z-index: 1;
   position: relative;
   color: #58144f;
   outline: none;
   border: none;
   transition: color 0.5s;
   cursor: pointer;
   border-radius: 50px;
   border: 2px solid #58144f;
   text-align: center;
   overflow: hidden;
   width: 7rem;
   height: 0.8rem;
   font-size: 18px;
   min-height: 45px;
   flex-direction: row;
   justify-content: center;
}

.blob-btn-text {
   outline: 0;
   text-decoration: none;
}
.blob-btn-text:hover {
   color: white !important;
}

.blob-btn:hover {
   color: white !important;
   border-radius: 50px;
   border: 2px solid transparent;
}
.blob-btn__inner {
   z-index: -1;
   overflow: hidden;
   position: absolute;
   left: 0;

   top: 0;
   width: 100%;
   height: 100%;
   border-radius: 30px;
   background: #ffffff;
}
.blob-btn__blobs {
   position: relative;
   display: block;
   height: 100%;
   -webkit-filter: url("#goo");
           filter: url("#goo");
}
.blob-btn__blob {
   position: absolute;
   top: 3px;
   width: 25%;
   height: 100%;
   background: linear-gradient(#8e27d7, #2e1472);
   border-radius: 100%;
   -webkit-transform: translate3d(0, 150%, 0) scale(1.7);
           transform: translate3d(0, 150%, 0) scale(1.7);
   transition: -webkit-transform 0.45s;
   transition: transform 0.45s;
   transition: transform 0.45s, -webkit-transform 0.45s;
}
@supports ((-webkit-filter: url("#goo")) or (filter: url("#goo"))) {
   .blob-btn__blob {
      -webkit-transform: translate3d(0, 150%, 0) scale(1.4);
              transform: translate3d(0, 150%, 0) scale(1.4);
   }
}
.blob-btn__blob:nth-child(1) {
   left: 0%;
   transition-delay: 0s;
}
.blob-btn__blob:nth-child(2) {
   left: 30%;
   transition-delay: 0.08s;
}
.blob-btn__blob:nth-child(3) {
   left: 60%;
   transition-delay: 0.16s;
}
.blob-btn__blob:nth-child(4) {
   left: 90%;
   transition-delay: 0.24s;
}
.blob-btn:hover .blob-btn__blob {
   -webkit-transform: translateZ(0) scale(1.7);
           transform: translateZ(0) scale(1.7);
}
@supports ((-webkit-filter: url("#goo")) or (filter: url("#goo"))) {
   .blob-btn:hover .blob-btn__blob {
      -webkit-transform: translateZ(0) scale(1.4);
              transform: translateZ(0) scale(1.4);
   }
}
@media (min-device-width: 20px) and (max-device-width: 500px) {
   html,
   body {
      overflow-x: hidden;
   }
   .heading {
      font-size: 32px;
   }

   .content {
      font-size: 15px;
   }
}

/*------------------ Navbar Section ------------------------------*/

.menu-wrap {
   position: fixed;
   top: 2%;
   left: 2%;
   z-index: 10;
}

.menu-wrap .toggler {
   position: absolute;
   top: 0;
   left: 0;
   z-index: 20;
   cursor: pointer;
   width: 50px;
   height: 50px;
   opacity: 0;
}

.menu-wrap .hamburger {
   position: absolute;
   z-index: 10;
   width: 60px;
   height: 60px;
   padding: 1rem;
   background: #1a1a1a;
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: 50%;
}

/* Hamburger Line */
.menu-wrap .hamburger > div {
   position: relative;
   flex: none;
   width: 100%;
   height: 2px;
   background: #fff;
   display: flex;
   align-items: center;
   justify-content: center;
   transition: all 0.4s ease;
}

/* Hamburger Lines - Top & Bottom */
.menu-wrap .hamburger > div::before,
.menu-wrap .hamburger > div::after {
   content: "";
   position: absolute;
   z-index: 20;
   top: -10px;
   width: 100%;
   height: 2px;
   background: inherit;
}

/* Moves Line Down */
.menu-wrap .hamburger > div::after {
   top: 10px;
}

/* Toggler Animation */
.menu-wrap .toggler:checked + .hamburger > div {
   -webkit-transform: rotate(135deg);
           transform: rotate(135deg);
}

/* Turns Lines Into X */
.menu-wrap .toggler:checked + .hamburger > div:before,
.menu-wrap .toggler:checked + .hamburger > div:after {
   top: 0;
   -webkit-transform: rotate(90deg);
           transform: rotate(90deg);
}

/* Show Menu */
.menu-wrap .toggler:checked ~ .menu {
   visibility: visible;
}

.menu-wrap .toggler:checked ~ .menu > div {
   -webkit-transform: scale(1);
           transform: scale(1);
   transition-duration: 1s;
}

.menu-wrap .toggler:checked ~ .menu > div > div {
   opacity: 1;
   transition: opacity 0.4s ease 0.4s;
}

.menu-wrap .menu {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   visibility: hidden;
   overflow: hidden;
   display: flex;
   align-items: center;
   justify-content: center;
}

.menu-wrap .menu > div {
   background: rgba(0, 0, 0, 0.96);
   border-radius: 50%;
   width: 200vw;
   height: 200vw;
   display: flex;
   flex: none;
   align-items: center;
   justify-content: center;
   -webkit-transform: scale(0);
           transform: scale(0);
   transition: all 0.4s ease;
}

.menu-wrap .menu > div > div {
   text-align: center;
   max-width: 90vw;
   max-height: 100vh;
   opacity: 0;
   transition: opacity 0.4s ease;
}

.menu-wrap .menu > div > div > ul > li {
   list-style: none;
   font-size: 1.7rem;
   padding-top: 7%;
}

.menu-wrap .menu > div > div > ul > li > a {
   color: #babdc0;
   font-family: "Raleway", sans-serif;
   text-decoration: none;
   /* font-weight: 500; */
   transition: color 0.4s ease;
}

.menu-wrap .menu > div > div > ul > li > a:hover,
.menu-wrap .menu > div > div > ul > li > a:focus {
   color: #ffffff;
}

.menu-wrap .menu > div > div > ul > li > a:hover:after {
   content: "";
   display: block;
   width: 50px;
   border-bottom: 2px solid #ffffff;
   margin: -2px auto;
   border-radius: 100px;
}
.navbar-brand1 {
   position: absolute;
   z-index: 200;
   width: 110px;
   height: 90px;
   top: 1%;
   right: 5%;
}

/*-------------------------------------- Landing Page  -------------------------------------- */

/* main div */

.animation-container {
   height: 125vh;
   background-color: #1a1a1a;
   position: relative;
}
.wave-pattern {
   position: absolute;
   bottom: -2px;
   top: -3px;
   left: 0;
   width: 100%;
   overflow: hidden;
   line-height: 0;
   -webkit-transform: rotate(180deg);
           transform: rotate(180deg);
}

.wave-pattern svg {
   position: relative;
   display: block;

   width: calc(135% + 1.3px);
   height: 120px;
}

.wave-pattern .shape-fill {
   fill: #fff;
}

/** For tablet devices **/
@media (min-width: 768px) and (max-width: 1023px) {
   .wave-pattern svg {
      width: calc(160% + 1.3px);
      height: 150px;
   }
}

/** For mobile devices **/
@media (max-width: 767px) {
   .wave-pattern svg {
      width: calc(174% + 1.3px);
      height: 100px;
   }
}

/*-------------------------------------- About Section --------------------------------------*/
.about {
   padding-left: 10%;
   padding-right: 10%;
   margin-top: 5%;
   background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3)), url(/static/media/bk2.844bcb8d.svg);
}

.about-logo {
   width: 80%;
}

.guidlines-btn {
   margin-left: 5%;
}
/*-------------------------------------- Event format --------------------------------------*/

.event-format {
   display: flex;
   flex-direction: row;
   justify-content: space-around;
   /* justify-content: space-between; */
}
.event-format-heading {
   background: linear-gradient(#587bec, #3c0569);
   -webkit-background-clip: text;
           background-clip: text;
   -webkit-text-fill-color: transparent;
}
.phase {
   margin: 1%;
   background: #fff;
   border-radius: 13px;
   padding: 1%;
   color: #1a1a1a;
   box-shadow: rgba(143, 129, 119, 0.2) 3px 3px 10px;
   transition: 2s ease-in-out;
}

.phase:hover {
   -webkit-transform: scale(1.5);
           transform: scale(1.5);
   box-shadow: rgba(102, 16, 102, 0.2) 0px 7px 19px 0px;
}

.event-format-content {
   color: #4f4955;
   padding: 7%;
   font-size: 16px;
}
/* Why participate */

.whyparticipate-heading {
   font-size: 29px;
   margin-bottom: 5%;
}

.whyparticipate-detail {
   padding: 1%;
   margin-left: 15%;
   margin-right: 15%;
}

.whyparticipate_boy {
   float: right;
}

.lookingfor-row {
   padding: 10px;
   margin-top: 3%;
   background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3)), url(/static/media/bk3.ac85f976.svg);
}
.lookingfor-content {
   padding-right: 8%;
   padding-left: 8%;
}
/* -------------------------------------- Rewards -------------------------------------- */
.rewards-row {
   padding: 10px;
   margin-top: 5%;
   margin: 3%;
}

.rewards-list {
   width: 70%;
   line-height: 35px;
}
.jnr-timeline-row {
   padding-top: 5%;

   background: linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.2)), url(/static/media/bk3.ac85f976.svg);
}

/** --------------------------------------------------- For mobile devices ---------------------------------------------------**/
@media (min-device-width: 20px) and (max-device-width: 700px) {
   html,
   body {
      overflow-x: hidden;
   }
   .heading {
      font-size: 29px;
   }

   .content {
      font-size: 16px;
   }
   .navbar-brand {
      position: absolute;
      z-index: 200;
      width: 90px;
      height: 50px;
      right: 1px;
      top: 10px;
   }
   .animation-container {
      height: 70vh;
      background-color: #1a1a1a;
      position: relative;
   }
   /*------------ Ibeto main page ------------*/
   /* landing page */
   .animation-container {
      width: 100vw;
   }
   .wave-shape-divider svg {
      width: calc(120% + 1.3px);
      height: 120px;
   }

   .abt-btn,
   .guidlines-btn {
      width: 6rem;
      min-height: 38px;
      font-size: 15px;
   }
   /* About */
   .about {
      background: #fff;
      text-align: center;
      margin-top: 5%;
   }
   .about-logo {
      width: 65%;
   }

   /* Event Format */

   .jnr-event_format {
      -webkit-transform: translateY(-7%) !important;
              transform: translateY(-7%) !important;
   }

   .phase {
      margin: 5%;
      margin-left: 12%;
      margin-bottom: 5%;
      margin-right: 12%;
      padding: 5%;
   }
   .event-format-content {
      text-align: center !important;
   }
   .phase-title {
      text-align: center;
   }
   /* Problem stmt */
   .whyparticipate-content {
      margin-left: 13%;
      margin-right: 10%;
   }
   .whyparticipate-detail {
      line-height: 26px;
      padding-bottom: 3%;
   }
   .whyparticipate_boy {
      float: left;
   }
   .whyparticipate_girl {
      float: right;
   }
   .whyparticipate_boy,
   .whyparticipate_girl {
      height: 160px;
      width: 160px;
   }
   .whyparticipate-detail {
      padding: 1%;
      margin-left: 1%;
      margin-right: 1%;
   }

   .lookingfor-row {
      margin-top: -10px;
      padding-right: 7%;
      padding-left: 7%;
      text-align: center;
   }

   .rewards-img2 {
      display: block;
   }
   .rewards-img1 {
      display: none;
   }
   /* Rewards */
   .rewards-row {
      display: block;
      margin: 0 auto;
      padding: 8%;
   }
   .reward-heading {
      text-align: center;
   }
   .rewards-img {
      width: 110%;
   }
   .rewards-list {
      margin-left: 8%;
      margin-right: 5%;
      width: 90%;
   }

   /* Sponsers */

   #Sponsors .client-logo {
      padding: 30px;
   }
   /* FAQ */
   #FAQ {
      background: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.2)), url(/static/media/bk5.a606d7f4.svg);
   }
   .accordion {
      padding-left: 10%;
      padding-right: 10%;
   }
   .accordion button .icon {
      width: 3px;
      height: 3px;
   }
   .faq-heading {
      width: 80%;
      display: block;
      margin: 0 auto;
   }
   .accordion button .accordion-title {
      padding: 0.4em 0.4em 0.6em 0;
   }
   .accordion-title,
   .collapse {
      font-size: 15px;
   }
   .accordion-title {
      width: 60%;
   }
   .social-icons .fa-facebook,
   .social-icons .fa-instagram,
   .social-icons .fa-linkedin-footer {
      font-size: 19px !important;
   }
   .footer-text {
      text-align: center;
      font-size: 14px;
   }
}

/** --------------------------------------------------- For tablet devices ---------------------------------------------------**/
@media (min-device-width: 501px) and (max-device-width: 800px) {
   /*Landing page wave*/
   .wave-shape-divider svg {
      width: calc(147% + 1.3px);
      height: 110px;
   }
   .card-container {
      display: flex;
      justify-content: center;
      align-items: center;
   }
   .phase {
      margin: 5%;
      margin-left: 5%;
      margin-right: 5%;
      text-align: justify;
      margin-bottom: 4%;
   }
   /* timeline */
   .mainsite-timeline-row {
      padding-top: 15%;
   }
   .mainsite-timeline-title {
      font-size: 16px;
   }
   /* junior section */
   .Jheading {
      left: 45px;
      font-size: 50px;
      right: 70px;
      bottom: 7px;
   }
   .Jbutton {
      right: 15px;
      width: 40px;
      height: 40px;
   }
}

/**--------------------------------------------------- For desktop devices ----------------------------------------------------- **/

@media (min-device-width: 800px) and (max-device-width: 1800px) {
   body {
      overflow-x: hidden;
   }
   /* Event format */
   .event-format {
      margin-left: 5%;
      margin-right: 5%;
   }
   .Jheading {
      font-size: 65px;
   }
   .Jbutton {
      width: 50px;
      height: 50px;
   }
   .rewards-img2 {
      display: none;
   }
   .whyparticipate_girl,
   .whyparticipate_boy {
      width: 300px;
      height: 300px;
   }
}

/**--------------------------------------------------- For Larger screen devices ----------------------------------------------------- **/
@media (min-width: 1900px) and (max-width: 3000px) {
   body {
      overflow-x: hidden;
   }
   .heading {
      font-size: 60px;
   }

   .content {
      font-size: 25px;
   }
   /* IBETO MAIN SITE */
   .wave-shape-divider svg {
      width: calc(140% + 1.3px);
      height: 191px;
   }

   /* event format */
   .event-format-content {
      font-size: 25px;
   }
   .rewards-img2 {
      display: none;
   }
   .whyparticipate_girl,
   .whyparticipate_boy {
      width: 300px;
      height: 300px;
   }
}

.hexa {
   border: 0px;
   float: left;
   text-align: center;
   height: 50px;
   width: 50px;
   font-size: 22px;
   background: #f0f0f0;
   color: #3c3c3c;
   position: relative;
   margin-top: 15px;
   z-index: 100;
   border-radius: 100%;
}

.timeline {
   position: relative;
   padding: 0;
   width: 100%;
   margin-top: 20px;
   list-style-type: none;
}

.timeline:before {
   position: absolute;
   left: 50%;
   top: 0;
   content: " ";
   display: block;
   width: 2px;
   height: 100%;
   margin-left: -1px;
   background: rgb(213, 213, 213);
   z-index: 0;
}

.timeline li {
   padding: 3em 0;
}

.timeline .hexa {
   width: 14px;
   height: 14px;
   position: absolute;
   background: #6956bd;
   z-index: 5;
   left: 0;
   right: 0;
   margin-left: auto;
   margin-right: auto;
   top: -30px;
   margin-top: 0;
}

.direction-l,
.direction-r {
   float: none;
   width: 100%;
   text-align: center;
}

.flag-wrapper {
   text-align: center;
   position: relative;
}

.flag {
   position: relative;
   display: inline;
   background: #fff;
   font-weight: 600;
   color: #140c22;
   z-index: 15;
   padding: 6px 10px;
   text-align: left;
   border-radius: 5px;
}

.direction-l .time-wrapper {
   float: none;
}

.direction-r .time-wrapper {
   float: none;
}

.time {
   display: block;
   position: relative;
   z-index: 14;
   color: #fff;
   background-image: linear-gradient(to right, #6b85da, #541688);
   display: inline-block;
   padding: 8px;
   margin-bottom: 10px;
   border-radius: 5%;
}

.direction-l .desc,
.direction-r .desc {
   position: relative;
   margin: 1em 1em 0 1em;
   padding: 1em;
   z-index: 15;
}

@media (min-width: 768px) {
   .timeline {
      width: 660px;
      margin: 0 auto;
      margin-top: 20px;
   }

   .timeline li:after {
      content: "";
      display: block;
      height: 0;
      clear: both;
      visibility: hidden;
   }

   .timeline .hexa {
      left: -28px;
      right: auto;
      top: 8px;
   }

   .timeline .direction-l .hexa {
      left: auto;
      right: -28px;
   }

   .direction-l {
      position: relative;
      width: 310px;
      float: left;
      text-align: right;
   }

   .direction-r {
      position: relative;
      width: 310px;
      float: right;
      text-align: left;
   }

   .flag-wrapper {
      display: inline-block;
   }

   .flag {
      font-size: 18px;
   }

   .direction-l .flag:after {
      left: auto;
      right: -16px;
      top: 50%;
      margin-top: -8px;
      border: solid transparent;
      border-left-color: rgb(254, 254, 254);
      border-width: 8px;
   }

   .direction-r .flag:after {
      top: 50%;
      margin-top: -8px;
      border: solid transparent;
      border-right-color: rgb(254, 254, 254);
      border-width: 8px;
      left: -8px;
   }

   .time-wrapper {
      display: inline;
      vertical-align: middle;
      margin: 0;
   }

   .direction-l .time-wrapper {
      float: left;
   }

   .direction-r .time-wrapper {
      float: right;
   }

   .time {
      padding: 4px 9px;
      font-size: 17px;
   }

   .direction-r .desc {
      margin: 1em 0 0 0.75em;
   }
}

@media (min-width: 992px) {
   .timeline {
      width: 800px;
      margin: 0 auto;
      margin-top: 20px;
   }

   .direction-l {
      position: relative;
      width: 380px;
      float: left;
      text-align: right;
   }

   .direction-r {
      position: relative;
      width: 380px;
      float: right;
      text-align: left;
   }
}

#FAQ {
   padding: 2%;
   margin-left: 5%;
   margin-right: 5%;
   background: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.2)), url(/static/media/bk5.a606d7f4.svg);
}
.faq-content {
   margin-left: 2%;
   margin-right: 2%;
}
.accordion-item {
   width: 90%;
}
.accordion .accordion-item {
   border-bottom: 1px solid #dddbdb;
}
.accordion .accordion-item button[aria-expanded="true"] {
   border-bottom: none;
}
.accordion button {
   position: relative;
   display: block;
   text-align: left;
   width: 100%;
   padding: 1em 0;
   padding-right: 10%;
   font-size: 1.15rem;
   font-weight: 400;
   border: none;
   background: none;
   outline: none;
}
.accordion button:hover,
.accordion button:focus {
   cursor: pointer;
   color: #1a1a1a;
}
.accordion button:hover::after,
.accordion button:focus::after {
   cursor: pointer;
   color: #1a1a1a;
   border: 1px solid #6956bd;
}
.accordion button .accordion-title {
   padding: 1em 1.5em 1em 0;
}
.accordion button .icon {
   display: inline-block;
   position: absolute;
   top: 18px;
   right: 0;
   width: 22px;
   height: 22px;
   border: 1px solid;
   border-radius: 22px;
   color: #2d2d2d;
}
.accordion button .icon::before {
   display: block;
   position: absolute;
   content: "";
   top: 9px;
   left: 5px;
   width: 10px;
   height: 2px;
   background: currentColor;
}
.accordion button .icon::after {
   display: block;
   position: absolute;
   content: "";
   top: 5px;
   left: 9px;
   width: 2px;
   height: 10px;
   background: currentColor;
}
.accordion button[aria-expanded="true"] {
   color: #6956bd;
   font-weight: 600;
}
.accordion button[aria-expanded="true"] .icon::after {
   width: 0;
}
.accordion button[aria-expanded="true"] + .accordion-content {
   opacity: 1;
   max-height: 9em;
   transition: all 200ms linear;
   will-change: opacity, max-height;
}
.accordion .accordion-content {
   opacity: 0;
   max-height: 0;
   overflow: hidden;
   transition: opacity 200ms linear, max-height 200ms linear;
   will-change: opacity, max-height;
}
.accordion .accordion-content p {
   font-size: 1rem;
   font-weight: 300;
   margin: 2em 0;
}
@media (min-width: 1900px) and (max-width: 3000px) {
   #FAQ {
      padding: 0%;
   }
   .accordion button {
      font-size: 25px;
   }
}

/* ------------------------------------ Footer ------------------------------------ */
.footer {
   background: #0a0e13;
   padding-bottom: 10px;
}
.footer123 {
   min-height: auto;
   margin: 0;
   padding: 0;
}
.footerDiv {
   width: 100%;
   max-width: 100vw;
   height: 20vh;
   padding-top: 1em;
   display: grid;
   grid-template-areas: "ibeto icon1 icon2 icon3 excel" ". brand brand brand .";
}

.footerIbeto {
   grid-area: ibeto;
   display: grid;
   margin: auto;
}

.icon1 {
   grid-area: icon1;
   margin: auto;
   margin-right: 0;
}

.icon2 {
   grid-area: icon2;
   margin: auto 0;
   text-align: center;
}

.icon3 {
   grid-area: icon3;
   margin: auto;
   margin-left: 0;
}

.footerExcel {
   grid-area: excel;
   display: grid;
   margin: auto;
}

.Excelbrand {
   grid-area: brand;
   display: grid;
   text-align: center;
}

.Excelbrand span {
   align-self: center;
   justify-self: center;
   color: white;
   font-size: 1rem;
}

.Excelbrand-text {
   margin-left: 10px;
   margin-top: -3% !important;
}

.footerIbeto img {
   justify-self: center;
   align-self: center;
   height: 5em;
}

.footerDiv a {
   color: white;
}

.footerExcel img {
   justify-self: center;
   align-self: center;
   height: 5em;
}

@media only screen and (max-width: 500px) {
   .footerDiv {
      grid-template-areas: "ibeto . . . excel" ". icon1 icon2 icon3 ." ". brand brand brand .";
      height: 180px;
      padding-top: 15px;
      font-size: 12px;
   }
   .Excelbrand-text {
      margin-left: 0;
   }
   .Excelbrand span {
      font-size: 14px !important;
   }
}

@media only screen and (max-width: 500px) {
   .footerDiv img {
      height: 42px;
   }
   .icon1 img,
   .icon2 img,
   .icon3 img {
      height: 33px;
   }
}

#contact {
   margin: 0 10% 0 25%;
}
.profile-container {
   padding: 1% 7% 1% 7%;
   background-image: url(/static/media/bk1.de2f5d55.svg);
}
.profile-card {
   padding: 40px 30px;
   font-family: "Open Sans", sans-serif;
   display: flex;
   flex-direction: column;
   align-items: center;
   border: #fff solid 1px;
   transition: 0.2s;
   box-shadow: rgba(77, 82, 88, 0.1) 0px 2px 2px 2px;
}
.profile-card:hover {
   -webkit-transform: scale(1.06);
           transform: scale(1.06);
   box-shadow: rgba(41, 41, 43, 0.2) 0px 7px 29px 10px;
   z-index: 10;
   border: none;
}

.profile-icon {
   height: 100px;
   width: 100px;
   object-fit: cover;
   border-radius: 50%;
}

.only-start {
   background: linear-gradient(to bottom, rgb(34, 33, 33), rgb(34, 33, 33) 100px, white 1rem, white 100%);
}

.profile-name {
   font-size: 21px;
   font-weight: bold;
   margin: 10px 0 1px 0;
}

.profile-position {
   font-size: 15px;
   color: rgb(53, 52, 52);
   text-align: center;
}
.profile-contact {
   font-size: 12px;
   color: #777;
}

@media screen and (max-width: 991px) {
   .profile-name {
      font-size: 18px;
   }

   .profile-container {
      padding: 4% 15% 4% 15%;
   }
}

@media screen and (max-width: 768px) {
   .profile-card {
      padding: 25px 15px;
   }

   .only-start {
      background: linear-gradient(to bottom, rgb(34, 33, 33), rgb(34, 33, 33) 80px, white 1rem, white 100%);
   }

   .profile-card {
      padding: 20px 0px;
   }

   .profile-name {
      font-size: 16px;
   }

   .profile-container {
      padding: 4% 25% 4% 25%;
   }
}

@media (min-device-width: 20px) and (max-device-width: 500px) {
   .profile-card {
      width: 50vw;
      padding: 5px;
      background-color: "blue";
   }
   .profile-icon {
      height: 70px;
      width: 70px;
      object-fit: cover;
      border-radius: 50%;
   }

   .only-start {
      background: linear-gradient(to bottom, rgb(34, 33, 33), rgb(34, 33, 33) 60px, white 1rem, white 70%);
   }
}

/* --------------------- Social Icons --------------------- */
.social-icons {
   display: flex;
   margin-top: 15%;
}

.social-icon {
   display: flex;
   align-items: center;
   justify-content: center;
   position: relative;
   width: 30px;
   height: 30px;

   margin: 0 0.5rem;
   border-radius: 50%;
   font-size: 2.5rem;
   text-decoration: none;
   transition: all 0.15s ease;
}
.social-icon:hover {
   color: #fff;
}
.social-icon--phone:hover .tooltip,
.social-icon--linkedin:hover .tooltip,
.social-icon--envelope:hover .tooltip {
   visibility: visible;
   opacity: 1;
   -webkit-transform: translate(-50%, -150%);
           transform: translate(-50%, -150%);
}
.social-icon:active {
   box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5) inset;
}
.social-icon--linkedin {
   background: #1a1a1a;

   color: #fff;
}
.social-icon--linkedin .tooltip {
   background: #1a1a1a;
   color: currentColor;
}
.social-icon--linkedin .tooltip:after {
   border-top-color: #1a1a1a;
}
.social-icon--envelope {
   background: #1a1a1a;

   color: #fff;
}
.social-icon--envelope .tooltip {
   background: #1a1a1a;
   color: currentColor;
}
.social-icon--envelope .tooltip:after {
   border-top-color: #1a1a1a;
}
.social-icon--phone {
   background: #1a1a1a;

   color: #fff;
}
.social-icon--phone .tooltip {
   background: #1a1a1a;
   color: currentColor;
}
.social-icon--phone .tooltip:after {
   border-top-color: #1a1a1a;
}
.social-icon i {
   position: relative;

   font-size: 16px;
}

/* Tooltips */
.tooltip {
   display: block;
   position: absolute;
   top: 0;
   left: 20%;
   padding: 0.2rem 0.5rem;
   border-radius: 40px;
   font-size: 0.8rem;
   color: white;
   -webkit-transform: translate(-50%, 100%);
           transform: translate(-50%, 100%);
   transition: all 0.3s ease;
   z-index: 1;
}
.tooltip:after {
   display: block;
   position: absolute;
   bottom: 1px;
   left: 50%;
   width: 0;
   height: 0;
   content: "";
   border: solid;
   border-width: 8px 8px 0 8px;
   border-color: transparent;
   -webkit-transform: translate(-45%, 100%);
           transform: translate(-45%, 100%);
}

#Sponsors {
   padding: 60px 0;
   display: block;
   margin-left: auto;
   margin-right: auto;
}
.sponsers-content {
   display: flex;
   align-items: center;
   justify-content: center;
}
#Sponsors .clients-wrap {
   margin-bottom: 30px;
   visibility: visible;
   -webkit-animation-name: fadeInUp;
           animation-name: fadeInUp;
}
#Sponsors .client-logo {
   padding: 64px;
   display: flex;
   justify-content: center;
   align-items: center;
   overflow: hidden;
   height: 160px;
}
#Sponsors img {
   transition: all 0.4s ease-in-out;
}

.loader_container {
   /* width: 100%;
   height: 100vh;
   color: #1a1a1a;
   z-index: 9999; */
   -webkit-transform: translateX(-7%);
           transform: translateX(-7%);
   top: calc(10% - 4em);
   left: 7%;
   align-items: center;
   background-color: #1a1a1a;
   display: flex;
   height: 100%;
   justify-content: center;
   margin: 0;
   position: absolute;
   width: 100%;
}

.filter {
   position: absolute;
   visibility: hidden;
}

.dots {
   -webkit-filter: url(#gooeyness);
           filter: url(#gooeyness);
   padding: 30px;
}

.dot {
   background: white;
   border-radius: 50%;
   display: inline-block;
   margin-right: 20px;
   width: 32px;
   height: 32px;
}

.dot:first-child {
   -webkit-animation: FirstDot 4.5s infinite;
           animation: FirstDot 4.5s infinite;
}

.dot:nth-child(2) {
   -webkit-animation: SecondDot 4.5s infinite;
           animation: SecondDot 4.5s infinite;
}

.dot:nth-child(3) {
   -webkit-animation: ThirdDot 4.5s infinite;
           animation: ThirdDot 4.5s infinite;
}

.dot:nth-child(4) {
   -webkit-animation: FourthDot 4.5s infinite;
           animation: FourthDot 4.5s infinite;
}

.dot:nth-child(5) {
   -webkit-animation: FifthDot 4.5s infinite;
           animation: FifthDot 4.5s infinite;
}

@-webkit-keyframes FirstDot {
   0% {
      -webkit-transform: scale(1) translateX(0);
              transform: scale(1) translateX(0);
   }
   25% {
      -webkit-transform: scale(2.5) translateX(0);
              transform: scale(2.5) translateX(0);
   }
   50% {
      -webkit-transform: scale(1) translateX(0);
              transform: scale(1) translateX(0);
   }
   83% {
      -webkit-transform: scale(1) translateX(240px);
              transform: scale(1) translateX(240px);
   }
   100% {
      -webkit-transform: scale(1) translateX(0);
              transform: scale(1) translateX(0);
   }
}

@keyframes FirstDot {
   0% {
      -webkit-transform: scale(1) translateX(0);
              transform: scale(1) translateX(0);
   }
   25% {
      -webkit-transform: scale(2.5) translateX(0);
              transform: scale(2.5) translateX(0);
   }
   50% {
      -webkit-transform: scale(1) translateX(0);
              transform: scale(1) translateX(0);
   }
   83% {
      -webkit-transform: scale(1) translateX(240px);
              transform: scale(1) translateX(240px);
   }
   100% {
      -webkit-transform: scale(1) translateX(0);
              transform: scale(1) translateX(0);
   }
}

@-webkit-keyframes SecondDot {
   0% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
   }
   27% {
      -webkit-transform: translateX(-40px);
              transform: translateX(-40px);
   }
   50% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
   }
   81% {
      -webkit-transform: translateX(180px);
              transform: translateX(180px);
   }
   100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
   }
}

@keyframes SecondDot {
   0% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
   }
   27% {
      -webkit-transform: translateX(-40px);
              transform: translateX(-40px);
   }
   50% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
   }
   81% {
      -webkit-transform: translateX(180px);
              transform: translateX(180px);
   }
   100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
   }
}

@-webkit-keyframes ThirdDot {
   0% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
   }
   29% {
      -webkit-transform: translateX(-100px);
              transform: translateX(-100px);
   }
   50% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
   }
   79% {
      -webkit-transform: translateX(120px);
              transform: translateX(120px);
   }
   100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
   }
}

@keyframes ThirdDot {
   0% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
   }
   29% {
      -webkit-transform: translateX(-100px);
              transform: translateX(-100px);
   }
   50% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
   }
   79% {
      -webkit-transform: translateX(120px);
              transform: translateX(120px);
   }
   100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
   }
}

@-webkit-keyframes FourthDot {
   0% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
   }
   31% {
      -webkit-transform: translateX(-160px);
              transform: translateX(-160px);
   }
   50% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
   }
   77% {
      -webkit-transform: translateX(60px);
              transform: translateX(60px);
   }
   100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
   }
}

@keyframes FourthDot {
   0% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
   }
   31% {
      -webkit-transform: translateX(-160px);
              transform: translateX(-160px);
   }
   50% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
   }
   77% {
      -webkit-transform: translateX(60px);
              transform: translateX(60px);
   }
   100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
   }
}

@-webkit-keyframes FifthDot {
   0% {
      -webkit-transform: scale(1) translateX(0);
              transform: scale(1) translateX(0);
   }
   33% {
      -webkit-transform: scale(1) translateX(-220px);
              transform: scale(1) translateX(-220px);
   }
   50% {
      -webkit-transform: scale(1) translateX(0);
              transform: scale(1) translateX(0);
   }
   75% {
      -webkit-transform: scale(2.5) translateX(0);
              transform: scale(2.5) translateX(0);
   }
   100% {
      -webkit-transform: scale(1) translateX(0);
              transform: scale(1) translateX(0);
   }
}

@keyframes FifthDot {
   0% {
      -webkit-transform: scale(1) translateX(0);
              transform: scale(1) translateX(0);
   }
   33% {
      -webkit-transform: scale(1) translateX(-220px);
              transform: scale(1) translateX(-220px);
   }
   50% {
      -webkit-transform: scale(1) translateX(0);
              transform: scale(1) translateX(0);
   }
   75% {
      -webkit-transform: scale(2.5) translateX(0);
              transform: scale(2.5) translateX(0);
   }
   100% {
      -webkit-transform: scale(1) translateX(0);
              transform: scale(1) translateX(0);
   }
}

/* .loader {
   position: absolute;
   top: calc(50% - 4em);
   left: calc(50% - 4em);
   width: 6em;
   height: 6em;
   border: 5px solid rgb(10, 10, 10);
   animation: load8 1.1s infinite linear;
   transition: opacity 0.3s;
 } */

.loader--hide {
   display: none;
}

