/** --------------------------------------------------- For mobile devices ---------------------------------------------------**/
@media (min-device-width: 20px) and (max-device-width: 700px) {
   html,
   body {
      overflow-x: hidden;
   }
   .heading {
      font-size: 29px;
   }

   .content {
      font-size: 16px;
   }
   .navbar-brand {
      position: absolute;
      z-index: 200;
      width: 90px;
      height: 50px;
      right: 1px;
      top: 10px;
   }
   .animation-container {
      height: 70vh;
      background-color: #1a1a1a;
      position: relative;
   }
   /*------------ Ibeto main page ------------*/
   /* landing page */
   .animation-container {
      width: 100vw;
   }
   .wave-shape-divider svg {
      width: calc(120% + 1.3px);
      height: 120px;
   }

   .abt-btn,
   .guidlines-btn {
      width: 6rem;
      min-height: 38px;
      font-size: 15px;
   }
   /* About */
   .about {
      background: #fff;
      text-align: center;
      margin-top: 5%;
   }
   .about-logo {
      width: 65%;
   }

   /* Event Format */

   .jnr-event_format {
      transform: translateY(-7%) !important;
   }

   .phase {
      margin: 5%;
      margin-left: 12%;
      margin-bottom: 5%;
      margin-right: 12%;
      padding: 5%;
   }
   .event-format-content {
      text-align: center !important;
   }
   .phase-title {
      text-align: center;
   }
   /* Problem stmt */
   .whyparticipate-content {
      margin-left: 13%;
      margin-right: 10%;
   }
   .whyparticipate-detail {
      line-height: 26px;
      padding-bottom: 3%;
   }
   .whyparticipate_boy {
      float: left;
   }
   .whyparticipate_girl {
      float: right;
   }
   .whyparticipate_boy,
   .whyparticipate_girl {
      height: 160px;
      width: 160px;
   }
   .whyparticipate-detail {
      padding: 1%;
      margin-left: 1%;
      margin-right: 1%;
   }

   .lookingfor-row {
      margin-top: -10px;
      padding-right: 7%;
      padding-left: 7%;
      text-align: center;
   }

   .rewards-img2 {
      display: block;
   }
   .rewards-img1 {
      display: none;
   }
   /* Rewards */
   .rewards-row {
      display: block;
      margin: 0 auto;
      padding: 8%;
   }
   .reward-heading {
      text-align: center;
   }
   .rewards-img {
      width: 110%;
   }
   .rewards-list {
      margin-left: 8%;
      margin-right: 5%;
      width: 90%;
   }

   /* Sponsers */

   #Sponsors .client-logo {
      padding: 30px;
   }
   /* FAQ */
   #FAQ {
      background: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.2)), url(../images/bk5.svg);
   }
   .accordion {
      padding-left: 10%;
      padding-right: 10%;
   }
   .accordion button .icon {
      width: 3px;
      height: 3px;
   }
   .faq-heading {
      width: 80%;
      display: block;
      margin: 0 auto;
   }
   .accordion button .accordion-title {
      padding: 0.4em 0.4em 0.6em 0;
   }
   .accordion-title,
   .collapse {
      font-size: 15px;
   }
   .accordion-title {
      width: 60%;
   }
   .social-icons .fa-facebook,
   .social-icons .fa-instagram,
   .social-icons .fa-linkedin-footer {
      font-size: 19px !important;
   }
   .footer-text {
      text-align: center;
      font-size: 14px;
   }
}

/** --------------------------------------------------- For tablet devices ---------------------------------------------------**/
@media (min-device-width: 501px) and (max-device-width: 800px) {
   /*Landing page wave*/
   .wave-shape-divider svg {
      width: calc(147% + 1.3px);
      height: 110px;
   }
   .card-container {
      display: flex;
      justify-content: center;
      align-items: center;
   }
   .phase {
      margin: 5%;
      margin-left: 5%;
      margin-right: 5%;
      text-align: justify;
      margin-bottom: 4%;
   }
   /* timeline */
   .mainsite-timeline-row {
      padding-top: 15%;
   }
   .mainsite-timeline-title {
      font-size: 16px;
   }
   /* junior section */
   .Jheading {
      left: 45px;
      font-size: 50px;
      right: 70px;
      bottom: 7px;
   }
   .Jbutton {
      right: 15px;
      width: 40px;
      height: 40px;
   }
}

/**--------------------------------------------------- For desktop devices ----------------------------------------------------- **/

@media (min-device-width: 800px) and (max-device-width: 1800px) {
   body {
      overflow-x: hidden;
   }
   /* Event format */
   .event-format {
      margin-left: 5%;
      margin-right: 5%;
   }
   .Jheading {
      font-size: 65px;
   }
   .Jbutton {
      width: 50px;
      height: 50px;
   }
   .rewards-img2 {
      display: none;
   }
   .whyparticipate_girl,
   .whyparticipate_boy {
      width: 300px;
      height: 300px;
   }
}

/**--------------------------------------------------- For Larger screen devices ----------------------------------------------------- **/
@media (min-width: 1900px) and (max-width: 3000px) {
   body {
      overflow-x: hidden;
   }
   .heading {
      font-size: 60px;
   }

   .content {
      font-size: 25px;
   }
   /* IBETO MAIN SITE */
   .wave-shape-divider svg {
      width: calc(140% + 1.3px);
      height: 191px;
   }

   /* event format */
   .event-format-content {
      font-size: 25px;
   }
   .rewards-img2 {
      display: none;
   }
   .whyparticipate_girl,
   .whyparticipate_boy {
      width: 300px;
      height: 300px;
   }
}
