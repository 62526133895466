/* ------------------------------------ Footer ------------------------------------ */
.footer {
   background: #0a0e13;
   padding-bottom: 10px;
}
.footer123 {
   min-height: auto;
   margin: 0;
   padding: 0;
}
.footerDiv {
   width: 100%;
   max-width: 100vw;
   height: 20vh;
   padding-top: 1em;
   display: grid;
   grid-template-areas: "ibeto icon1 icon2 icon3 excel" ". brand brand brand .";
}

.footerIbeto {
   grid-area: ibeto;
   display: grid;
   margin: auto;
}

.icon1 {
   grid-area: icon1;
   margin: auto;
   margin-right: 0;
}

.icon2 {
   grid-area: icon2;
   margin: auto 0;
   text-align: center;
}

.icon3 {
   grid-area: icon3;
   margin: auto;
   margin-left: 0;
}

.footerExcel {
   grid-area: excel;
   display: grid;
   margin: auto;
}

.Excelbrand {
   grid-area: brand;
   display: grid;
   text-align: center;
}

.Excelbrand span {
   align-self: center;
   justify-self: center;
   color: white;
   font-size: 1rem;
}

.Excelbrand-text {
   margin-left: 10px;
   margin-top: -3% !important;
}

.footerIbeto img {
   justify-self: center;
   align-self: center;
   height: 5em;
}

.footerDiv a {
   color: white;
}

.footerExcel img {
   justify-self: center;
   align-self: center;
   height: 5em;
}

@media only screen and (max-width: 500px) {
   .footerDiv {
      grid-template-areas: "ibeto . . . excel" ". icon1 icon2 icon3 ." ". brand brand brand .";
      height: 180px;
      padding-top: 15px;
      font-size: 12px;
   }
   .Excelbrand-text {
      margin-left: 0;
   }
   .Excelbrand span {
      font-size: 14px !important;
   }
}

@media only screen and (max-width: 500px) {
   .footerDiv img {
      height: 42px;
   }
   .icon1 img,
   .icon2 img,
   .icon3 img {
      height: 33px;
   }
}
