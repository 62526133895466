.hexa {
   border: 0px;
   float: left;
   text-align: center;
   height: 50px;
   width: 50px;
   font-size: 22px;
   background: #f0f0f0;
   color: #3c3c3c;
   position: relative;
   margin-top: 15px;
   z-index: 100;
   border-radius: 100%;
}

.timeline {
   position: relative;
   padding: 0;
   width: 100%;
   margin-top: 20px;
   list-style-type: none;
}

.timeline:before {
   position: absolute;
   left: 50%;
   top: 0;
   content: " ";
   display: block;
   width: 2px;
   height: 100%;
   margin-left: -1px;
   background: rgb(213, 213, 213);
   z-index: 0;
}

.timeline li {
   padding: 3em 0;
}

.timeline .hexa {
   width: 14px;
   height: 14px;
   position: absolute;
   background: #6956bd;
   z-index: 5;
   left: 0;
   right: 0;
   margin-left: auto;
   margin-right: auto;
   top: -30px;
   margin-top: 0;
}

.direction-l,
.direction-r {
   float: none;
   width: 100%;
   text-align: center;
}

.flag-wrapper {
   text-align: center;
   position: relative;
}

.flag {
   position: relative;
   display: inline;
   background: #fff;
   font-weight: 600;
   color: #140c22;
   z-index: 15;
   padding: 6px 10px;
   text-align: left;
   border-radius: 5px;
}

.direction-l .time-wrapper {
   float: none;
}

.direction-r .time-wrapper {
   float: none;
}

.time {
   display: block;
   position: relative;
   z-index: 14;
   color: #fff;
   background-image: linear-gradient(to right, #6b85da, #541688);
   display: inline-block;
   padding: 8px;
   margin-bottom: 10px;
   border-radius: 5%;
}

.direction-l .desc,
.direction-r .desc {
   position: relative;
   margin: 1em 1em 0 1em;
   padding: 1em;
   z-index: 15;
}

@media (min-width: 768px) {
   .timeline {
      width: 660px;
      margin: 0 auto;
      margin-top: 20px;
   }

   .timeline li:after {
      content: "";
      display: block;
      height: 0;
      clear: both;
      visibility: hidden;
   }

   .timeline .hexa {
      left: -28px;
      right: auto;
      top: 8px;
   }

   .timeline .direction-l .hexa {
      left: auto;
      right: -28px;
   }

   .direction-l {
      position: relative;
      width: 310px;
      float: left;
      text-align: right;
   }

   .direction-r {
      position: relative;
      width: 310px;
      float: right;
      text-align: left;
   }

   .flag-wrapper {
      display: inline-block;
   }

   .flag {
      font-size: 18px;
   }

   .direction-l .flag:after {
      left: auto;
      right: -16px;
      top: 50%;
      margin-top: -8px;
      border: solid transparent;
      border-left-color: rgb(254, 254, 254);
      border-width: 8px;
   }

   .direction-r .flag:after {
      top: 50%;
      margin-top: -8px;
      border: solid transparent;
      border-right-color: rgb(254, 254, 254);
      border-width: 8px;
      left: -8px;
   }

   .time-wrapper {
      display: inline;
      vertical-align: middle;
      margin: 0;
   }

   .direction-l .time-wrapper {
      float: left;
   }

   .direction-r .time-wrapper {
      float: right;
   }

   .time {
      padding: 4px 9px;
      font-size: 17px;
   }

   .direction-r .desc {
      margin: 1em 0 0 0.75em;
   }
}

@media (min-width: 992px) {
   .timeline {
      width: 800px;
      margin: 0 auto;
      margin-top: 20px;
   }

   .direction-l {
      position: relative;
      width: 380px;
      float: left;
      text-align: right;
   }

   .direction-r {
      position: relative;
      width: 380px;
      float: right;
      text-align: left;
   }
}
