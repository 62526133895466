/*------------------ Navbar Section ------------------------------*/

.menu-wrap {
   position: fixed;
   top: 2%;
   left: 2%;
   z-index: 10;
}

.menu-wrap .toggler {
   position: absolute;
   top: 0;
   left: 0;
   z-index: 20;
   cursor: pointer;
   width: 50px;
   height: 50px;
   opacity: 0;
}

.menu-wrap .hamburger {
   position: absolute;
   z-index: 10;
   width: 60px;
   height: 60px;
   padding: 1rem;
   background: #1a1a1a;
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: 50%;
}

/* Hamburger Line */
.menu-wrap .hamburger > div {
   position: relative;
   flex: none;
   width: 100%;
   height: 2px;
   background: #fff;
   display: flex;
   align-items: center;
   justify-content: center;
   transition: all 0.4s ease;
}

/* Hamburger Lines - Top & Bottom */
.menu-wrap .hamburger > div::before,
.menu-wrap .hamburger > div::after {
   content: "";
   position: absolute;
   z-index: 20;
   top: -10px;
   width: 100%;
   height: 2px;
   background: inherit;
}

/* Moves Line Down */
.menu-wrap .hamburger > div::after {
   top: 10px;
}

/* Toggler Animation */
.menu-wrap .toggler:checked + .hamburger > div {
   transform: rotate(135deg);
}

/* Turns Lines Into X */
.menu-wrap .toggler:checked + .hamburger > div:before,
.menu-wrap .toggler:checked + .hamburger > div:after {
   top: 0;
   transform: rotate(90deg);
}

/* Show Menu */
.menu-wrap .toggler:checked ~ .menu {
   visibility: visible;
}

.menu-wrap .toggler:checked ~ .menu > div {
   transform: scale(1);
   transition-duration: 1s;
}

.menu-wrap .toggler:checked ~ .menu > div > div {
   opacity: 1;
   transition: opacity 0.4s ease 0.4s;
}

.menu-wrap .menu {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   visibility: hidden;
   overflow: hidden;
   display: flex;
   align-items: center;
   justify-content: center;
}

.menu-wrap .menu > div {
   background: rgba(0, 0, 0, 0.96);
   border-radius: 50%;
   width: 200vw;
   height: 200vw;
   display: flex;
   flex: none;
   align-items: center;
   justify-content: center;
   transform: scale(0);
   transition: all 0.4s ease;
}

.menu-wrap .menu > div > div {
   text-align: center;
   max-width: 90vw;
   max-height: 100vh;
   opacity: 0;
   transition: opacity 0.4s ease;
}

.menu-wrap .menu > div > div > ul > li {
   list-style: none;
   font-size: 1.7rem;
   padding-top: 7%;
}

.menu-wrap .menu > div > div > ul > li > a {
   color: #babdc0;
   font-family: "Raleway", sans-serif;
   text-decoration: none;
   /* font-weight: 500; */
   transition: color 0.4s ease;
}

.menu-wrap .menu > div > div > ul > li > a:hover,
.menu-wrap .menu > div > div > ul > li > a:focus {
   color: #ffffff;
}

.menu-wrap .menu > div > div > ul > li > a:hover:after {
   content: "";
   display: block;
   width: 50px;
   border-bottom: 2px solid #ffffff;
   margin: -2px auto;
   border-radius: 100px;
}
.navbar-brand1 {
   position: absolute;
   z-index: 200;
   width: 110px;
   height: 90px;
   top: 1%;
   right: 5%;
}

/*-------------------------------------- Landing Page  -------------------------------------- */

/* main div */

.animation-container {
   height: 125vh;
   background-color: #1a1a1a;
   position: relative;
}
.wave-pattern {
   position: absolute;
   bottom: -2px;
   top: -3px;
   left: 0;
   width: 100%;
   overflow: hidden;
   line-height: 0;
   transform: rotate(180deg);
}

.wave-pattern svg {
   position: relative;
   display: block;

   width: calc(135% + 1.3px);
   height: 120px;
}

.wave-pattern .shape-fill {
   fill: #fff;
}

/** For tablet devices **/
@media (min-width: 768px) and (max-width: 1023px) {
   .wave-pattern svg {
      width: calc(160% + 1.3px);
      height: 150px;
   }
}

/** For mobile devices **/
@media (max-width: 767px) {
   .wave-pattern svg {
      width: calc(174% + 1.3px);
      height: 100px;
   }
}

/*-------------------------------------- About Section --------------------------------------*/
.about {
   padding-left: 10%;
   padding-right: 10%;
   margin-top: 5%;
   background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3)), url(../images/bk2.svg);
}

.about-logo {
   width: 80%;
}

.guidlines-btn {
   margin-left: 5%;
}
/*-------------------------------------- Event format --------------------------------------*/

.event-format {
   display: flex;
   flex-direction: row;
   justify-content: space-around;
   /* justify-content: space-between; */
}
.event-format-heading {
   background: linear-gradient(#587bec, #3c0569);
   background-clip: text;
   -webkit-text-fill-color: transparent;
}
.phase {
   margin: 1%;
   background: #fff;
   border-radius: 13px;
   padding: 1%;
   color: #1a1a1a;
   box-shadow: rgba(143, 129, 119, 0.2) 3px 3px 10px;
   transition: 2s ease-in-out;
}

.phase:hover {
   transform: scale(1.5);
   box-shadow: rgba(102, 16, 102, 0.2) 0px 7px 19px 0px;
}

.event-format-content {
   color: #4f4955;
   padding: 7%;
   font-size: 16px;
}
/* Why participate */

.whyparticipate-heading {
   font-size: 29px;
   margin-bottom: 5%;
}

.whyparticipate-detail {
   padding: 1%;
   margin-left: 15%;
   margin-right: 15%;
}

.whyparticipate_boy {
   float: right;
}

.lookingfor-row {
   padding: 10px;
   margin-top: 3%;
   background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3)), url(../images/bk3.svg);
}
.lookingfor-content {
   padding-right: 8%;
   padding-left: 8%;
}
/* -------------------------------------- Rewards -------------------------------------- */
.rewards-row {
   padding: 10px;
   margin-top: 5%;
   margin: 3%;
}

.rewards-list {
   width: 70%;
   line-height: 35px;
}
.jnr-timeline-row {
   padding-top: 5%;

   background: linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.2)), url(../images/bk3.svg);
}
