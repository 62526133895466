#contact {
   margin: 0 10% 0 25%;
}
.profile-container {
   padding: 1% 7% 1% 7%;
   background-image: url(../images/bk1.svg);
}
.profile-card {
   padding: 40px 30px;
   font-family: "Open Sans", sans-serif;
   display: flex;
   flex-direction: column;
   align-items: center;
   border: #fff solid 1px;
   transition: 0.2s;
   box-shadow: rgba(77, 82, 88, 0.1) 0px 2px 2px 2px;
}
.profile-card:hover {
   transform: scale(1.06);
   box-shadow: rgba(41, 41, 43, 0.2) 0px 7px 29px 10px;
   z-index: 10;
   border: none;
}

.profile-icon {
   height: 100px;
   width: 100px;
   object-fit: cover;
   border-radius: 50%;
}

.only-start {
   background: linear-gradient(to bottom, rgb(34, 33, 33), rgb(34, 33, 33) 100px, white 1rem, white 100%);
}

.profile-name {
   font-size: 21px;
   font-weight: bold;
   margin: 10px 0 1px 0;
}

.profile-position {
   font-size: 15px;
   color: rgb(53, 52, 52);
   text-align: center;
}
.profile-contact {
   font-size: 12px;
   color: #777;
}

@media screen and (max-width: 991px) {
   .profile-name {
      font-size: 18px;
   }

   .profile-container {
      padding: 4% 15% 4% 15%;
   }
}

@media screen and (max-width: 768px) {
   .profile-card {
      padding: 25px 15px;
   }

   .only-start {
      background: linear-gradient(to bottom, rgb(34, 33, 33), rgb(34, 33, 33) 80px, white 1rem, white 100%);
   }

   .profile-card {
      padding: 20px 0px;
   }

   .profile-name {
      font-size: 16px;
   }

   .profile-container {
      padding: 4% 25% 4% 25%;
   }
}

@media (min-device-width: 20px) and (max-device-width: 500px) {
   .profile-card {
      width: 50vw;
      padding: 5px;
      background-color: "blue";
   }
   .profile-icon {
      height: 70px;
      width: 70px;
      object-fit: cover;
      border-radius: 50%;
   }

   .only-start {
      background: linear-gradient(to bottom, rgb(34, 33, 33), rgb(34, 33, 33) 60px, white 1rem, white 70%);
   }
}

/* --------------------- Social Icons --------------------- */
.social-icons {
   display: flex;
   margin-top: 15%;
}

.social-icon {
   display: flex;
   align-items: center;
   justify-content: center;
   position: relative;
   width: 30px;
   height: 30px;

   margin: 0 0.5rem;
   border-radius: 50%;
   font-size: 2.5rem;
   text-decoration: none;
   transition: all 0.15s ease;
}
.social-icon:hover {
   color: #fff;
}
.social-icon--phone:hover .tooltip,
.social-icon--linkedin:hover .tooltip,
.social-icon--envelope:hover .tooltip {
   visibility: visible;
   opacity: 1;
   transform: translate(-50%, -150%);
}
.social-icon:active {
   box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5) inset;
}
.social-icon--linkedin {
   background: #1a1a1a;

   color: #fff;
}
.social-icon--linkedin .tooltip {
   background: #1a1a1a;
   color: currentColor;
}
.social-icon--linkedin .tooltip:after {
   border-top-color: #1a1a1a;
}
.social-icon--envelope {
   background: #1a1a1a;

   color: #fff;
}
.social-icon--envelope .tooltip {
   background: #1a1a1a;
   color: currentColor;
}
.social-icon--envelope .tooltip:after {
   border-top-color: #1a1a1a;
}
.social-icon--phone {
   background: #1a1a1a;

   color: #fff;
}
.social-icon--phone .tooltip {
   background: #1a1a1a;
   color: currentColor;
}
.social-icon--phone .tooltip:after {
   border-top-color: #1a1a1a;
}
.social-icon i {
   position: relative;

   font-size: 16px;
}

/* Tooltips */
.tooltip {
   display: block;
   position: absolute;
   top: 0;
   left: 20%;
   padding: 0.2rem 0.5rem;
   border-radius: 40px;
   font-size: 0.8rem;
   color: white;
   transform: translate(-50%, 100%);
   transition: all 0.3s ease;
   z-index: 1;
}
.tooltip:after {
   display: block;
   position: absolute;
   bottom: 1px;
   left: 50%;
   width: 0;
   height: 0;
   content: "";
   border: solid;
   border-width: 8px 8px 0 8px;
   border-color: transparent;
   transform: translate(-45%, 100%);
}
