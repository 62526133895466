#Sponsors {
   padding: 60px 0;
   display: block;
   margin-left: auto;
   margin-right: auto;
}
.sponsers-content {
   display: flex;
   align-items: center;
   justify-content: center;
}
#Sponsors .clients-wrap {
   margin-bottom: 30px;
   visibility: visible;
   animation-name: fadeInUp;
}
#Sponsors .client-logo {
   padding: 64px;
   display: -webkit-box;
   display: -webkit-flex;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-pack: center;
   -webkit-justify-content: center;
   -ms-flex-pack: center;
   justify-content: center;
   -webkit-box-align: center;
   -webkit-align-items: center;
   -ms-flex-align: center;
   align-items: center;
   overflow: hidden;
   height: 160px;
}
#Sponsors img {
   transition: all 0.4s ease-in-out;
}
